import React from "react"
import Obfuscate from 'react-obfuscate';

import Layout from "../components/layout"
import Seo from "../components/seo"

import PhoneIcon from "../assets/svg/icons/phone.svg"
import EmailIcon from "../assets/svg/icons/mail.svg"
import MapIcon from "../assets/svg/icons/directions.svg"

const IndexPage = () => (
  <Layout>
    <Seo title="Impressum" />
    <section className="page">
      <h1 className="text_huge">Impressum</h1>
      <div className="flexbox">
        <div className="flexbox-container-cont">
          <h2 className="text_big">NETengine GmbH</h2>
          <ul className="nodotList">

            <li className="with-icon">
              <a
                href="https://goo.gl/maps/VUVBRWMy9LSWfpmb8"
                aria-label="Adresse in Google Maps öffnen"
                target="_blank"
                rel="noopener noreferrer"
              ><MapIcon />Kornmarktstraße 18
              </a>
            </li>
            <li className="with-icon">
              <a
                href="https://goo.gl/maps/VUVBRWMy9LSWfpmb8"
                aria-label="Adresse in Google Maps öffnen"
                target="_blank"
                rel="noopener noreferrer"
              >6900 Bregenz, Austria
              </a>
            </li>
            <li className="with-icon"><EmailIcon /><Obfuscate email="office@netengine.at" /></li>
            <li className="with-icon"><PhoneIcon /><Obfuscate tel="0043557422538">+43 (0)5574 22538</Obfuscate></li>
          </ul>

          <ul className="nodotList">
            <li>Gesellschaft mit beschränkter Haftung</li>
            <li>EDV Dienstleister</li>
            <li><span className="bold">UID-Nr:</span> ATU69772659</li>
            <li><span className="bold">Firmenbuchnummer:</span> 437605 m</li>
            <li><span className="bold">FB-Gericht:</span> LG Feldkirch</li>
          </ul>
        </div>

        <div className="flexbox-container-cont flex-top">
          <h2 className="text_big ">Bürozeiten</h2>
          <ul className="nodotList">
            <li className="bold">Montag bis Donnerstag</li>
            <li>08:00 - 17:00 Uhr</li>
            <li className="bold">Freitag</li>
            <li>08:00 - 15:00 Uhr</li>
          </ul>
        </div>

        <div className="flexbox-container-cont">
          <ul className="nodotList">
            <li><span className="bold">Geschäftsführer:</span> Amon Vogel, Ulf Albl</li>
            <li>Mitglied der Fachgruppe für Unternehmensberatung und Informationstechnologie der Wirtschaftskammer Vorarlberg.</li>
            <li>Fehler und Irrtümer vorbehalten. Alle Angaben auf dieser Website ohne Gewähr.</li>
          </ul>
          <a className="text_big" href="/agbs">Allgemeine Bedingungen</a>
        </div>

      </div>
    </section>
  </Layout>
)

export default IndexPage
